import {Logo} from"../../components/Logo"
export default function Admin(){

    return(
    <div>
        <Logo/>
        <h1>Pagina Admin</h1>
    </div>
    )

}