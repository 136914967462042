import './index.css';
export function Conteudo (){
    return(
    <div className='conteudo'>
        <h1>Sou o Fagner</h1>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lobortis in orci sed scelerisque. Mauris erat nibh, dignissim sed massa vehicula, maximus imperdiet sapien. Sed id dui sapien. Sed viverra non nisi eget ultricies. Nulla laoreet consectetur mauris, vestibulum laoreet ligula elementum eget. Nullam ante tellus, luctus eget mi vel, commodo tincidunt odio. Suspendisse laoreet erat leo, eget egestas orci vestibulum vitae. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Suspendisse auctor egestas est et pulvinar. Aliquam finibus sem id semper volutpat.
        </p>
    </div>
       
    )
}
